<template>
  <div class="wrapper">
    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="header-logo_title">CAT MONEY</div>
        </div>
      </div>
    </header>

    <section class="my-profile">
      <div class="balance-card">
        <div class="balance-main">
          <div class="balance-divider-y"></div>
          <div class="d-flex flex-column align-items-center">
            <p class="balance-title">Tổng tài sản</p>
            <p class="balance-money">₫ {{ formatMoney(user.balance) }}</p>
          </div>
          <div class="d-flex flex-column align-items-center">
            <p class="balance-title">Hoa hồng</p>
            <p class="balance-money">₫ {{ formatMoney(user.rose) }}</p>
          </div>
        </div>

        <div class="deposit-withdraw">
          <div class="deposit-withdraw-main">
            <div class="deposit-withdraw_btn">
              <div class="deposit-withdraw_img">
                <img src="@/assets/img/deposit.png" alt="" />
              </div>
              <div class="deposit-withdraw_title">
                <p class="deposit-withdraw_text">Tổng nạp</p>
                <p class="deposit-withdraw_subtext">{{formatMoney(request.totalRecharge)}} đ</p>
              </div>
            </div>

            <div class="deposit-withdraw_btn">
              <div class="deposit-withdraw_img">
                <img src="@/assets/img/withdraw.png" alt="" />
              </div>
              <div class="deposit-withdraw_title">
                <p class="deposit-withdraw_text">Tổng rút</p>
                <p class="deposit-withdraw_subtext">{{formatMoney(request.totalWithdraw)}} đ</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="profile-action">
      <p class="profile-action-title">Quản lý nguồn tiền</p>
      <div class="profile-action-main">
        <router-link to="/money-history">
          <div class="d-flex justify-content-between align-items-center cursor-pointer">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-history profile-action_items-icon"></i>
              <div class="d-flex flex-column">
                <p class="profile-action_items-text">Lịch sử nguồn tiền</p>
                <p class="profile-action_items-subtext">
                  Xem lịch sử biến động số dư
                </p>
              </div>
            </div>
            <div><i class="ti ti-chevron-right"></i></div>
          </div>
        </router-link>
        <div class="d-flex justify-content-between align-items-center cursor-pointer">
          <div class="d-flex align-items-center gap-2">
            <i class="ti ti-chart-pie profile-action_items-icon"></i>
            <div class="d-flex flex-column">
              <p class="profile-action_items-text">Quản lý thu chi</p>
              <p class="profile-action_items-subtext">Theo dõi thu chi</p>
            </div>
          </div>
          <div><i class="ti ti-chevron-right"></i></div>
        </div>
        <div class="d-flex justify-content-between align-items-center cursor-pointer">
          <div class="d-flex align-items-center gap-2">
            <i class="ti ti-qrcode profile-action_items-icon"></i>
            <div class="d-flex flex-column">
              <p class="profile-action_items-text">QR thanh toán</p>
              <p class="profile-action_items-subtext">
                QR nhận tiền của tôi
              </p>
            </div>
          </div>
          <div><i class="ti ti-chevron-right"></i></div>
        </div>
        <div class="d-flex justify-content-between align-items-center cursor-pointer">
          <div class="d-flex align-items-center gap-2">
            <i class="ti ti-pig-money profile-action_items-icon"></i>
            <div class="d-flex flex-column">
              <p class="profile-action_items-text">Tài khoản tích lũy</p>
              <p class="profile-action_items-subtext">Sinh lời mỗi ngày</p>
            </div>
          </div>
          <div><i class="ti ti-chevron-right"></i></div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/user/components/Footer'
import network from '@/core/network'
export default {
  components: {
    Footer
  },
  data() {
    return {
      user: {},
      request:{}
    }
  },
  mounted() {
    this.getProfile();
    this.getTotalHistoryRequest();
  },
  methods: {
    getProfile() {
        network.get('/users/profile').then( res => {
          console.log(res.data.data);
          this.user = res.data.data;
        }).catch(err => {
          console.log(err);
        })
      },
    getTotalHistoryRequest() {
      network.get('/requests/totalChange').then( res => {
        console.log(res);
        this.request = res.data;
      });
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },
    formatMoney(number) {

      return parseInt(number).toLocaleString('vi-VN', {
        useGrouping: true,
        minimumFractionDigits: 0
      }).replace(/\./g, ',');
    }
  }

}
</script>

<style></style>